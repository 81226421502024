import React, { useState } from 'react';
import Rails from 'rails-ujs';
import axios from 'axios';

type Props = {
  followerAccountUid: string,
  isFollowerAccountLocked: boolean,
  defaultIsActive: boolean,
  defaultLotMultiplierPct: number,
  defaultReverseTrades: boolean,
  defaultFetchedAtText: string|null,
  defaultFetchedAgoText: string|null,
};

const SubscriptionColumnContent: React.FC<Props> = ({
  followerAccountUid,
  isFollowerAccountLocked,
  defaultIsActive,
  defaultLotMultiplierPct,
  defaultReverseTrades,
  defaultFetchedAtText,
  defaultFetchedAgoText,
}) => {
  const [isActive, setIsActive] = useState<boolean>(defaultIsActive);
  const [lotMultiplierPct, setLotMultiplierPct] = useState<number>(defaultLotMultiplierPct);
  const [reverseTrades, setReverseTrades] = useState<boolean>(defaultReverseTrades);
  const [fetchedAtText, setFetchedAtText] = useState<string|null>(defaultFetchedAtText);
  const [fetchedAgoText, setFetchedAgoText] = useState<string|null>(defaultFetchedAgoText);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdate = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const url = `/p/follower_accounts/${followerAccountUid}/update_account`;
      const response = await axios.put(url, {
         authenticity_token: Rails.csrfToken(),
      }, {
        timeout: 8000,
      });
      if (response.status === 200) {
        setIsActive(response.data.isActive);
        setLotMultiplierPct(response.data.lotMultiplierPct);
        setReverseTrades(response.data.reverseTrades);
        setFetchedAtText(response.data.fetchedAtText);
        setFetchedAgoText(response.data.fetchedAgoText);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (e) {
      setIsLoading(false);
      if (e.response.data?.message) {
        alert(e.response.data.message);
      } else {
        console.log('Something went wrong');
        console.log(e);
      }
    }
  }

  return (
    <>
      {!!fetchedAtText && <>
        {isActive ?
          <span>Active ({lotMultiplierPct}%)</span> : (
          <span>{isFollowerAccountLocked ? 'Locked' : 'Inactive'}</span>
        )}
        {reverseTrades && <span className="badge bg-warning text-dark ms-2">Reverse</span>}
        <br />
      </>}
      <div className="d-flex">
        {!!fetchedAtText ? <a
          title={fetchedAtText?.toString()}
          onClick={(e) => { e.preventDefault(); }}
          className="text-muted text-small text-undecorated d-block me-1">
          {fetchedAgoText}
        </a> : <div className="text-muted me-1">
          {fetchedAgoText ?? 'Unknown'}
        </div>}
        {!isLoading && <a onClick={handleUpdate} href="#" className="text-cyan">
          <i className="las la-sync-alt"></i>
        </a>}
        {isLoading && <div className="text-spin"><i className="las la-sync-alt"></i></div>}
      </div>
    </>
  );
}

export default SubscriptionColumnContent;