import React, { useState, Suspense, useEffect } from 'react';
import OpenTradeModal from './open_trade_modal';
import CurrencyIcon from '../currency_icon';
import { formatCurrency } from '../../../utils/format_currency';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultProfit: string;
  ticket: number;
  accountNumber: number;
  accountCurrency: string | null;
  baseCurrency: string;
  quoteCurrency: string;
  side: string;
  openTimeText: string;
  openTimeShortText: string;
  openTimeWarning: string | null;
  openPrice: string;
  currentPrice: string;
  takeProfit: string;
  stopLoss: string;
  locale?: string;
};

const TradeCard: React.FC<Props> = ({
  defaultProfit,
  ticket,
  accountNumber,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  side,
  openTimeText,
  openTimeShortText,
  openTimeWarning,
  openPrice,
  currentPrice,
  takeProfit,
  stopLoss,
  locale,
}) => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState<boolean>(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => {
    if (hasChange) {
      window.location.reload();
    } else {
      setShowModal(false);
    }
  };

  if (!!locale) {
    useEffect(() => {
      i18n.changeLanguage(locale);
    }, [locale]);
  }

  return (
    <>
      <a href="#"
        className={`open-position-card open-position-card_${side.toLowerCase()}`}
        onClick={(e) => { e.preventDefault(); handleOpen(); }}>
        <div className="open-position-card__symbol-column">
          <div className="open-position-card__flags">
            <CurrencyIcon currency={baseCurrency} />{' '}<CurrencyIcon currency={quoteCurrency} />
          </div>
          {`${baseCurrency} / ${quoteCurrency}`}
        </div>
        <div className="open-position-card__main-column">
          <div className="open-position-card__ticket">
            #{ticket}
          </div>
          <div className="open-position-card__title">
            <span className="open-position-card__side-text">{side.toUpperCase()}</span>
            {' '}
            <span className="open-position-card__date-text">{openTimeText}</span>
          </div>
          <div className="open-position-card__price-column-list">
            <div className="open-position-card__price-column">
              <div className="open-position-card__label">{t('trade.open_price')}</div>
              <div className="open-position-card__value">{openPrice}</div>
            </div>
            <div className="open-position-card__price-column">
              <div className="open-position-card__label">{t('trade.current_price')}</div>
              <div className="open-position-card__value">{currentPrice}</div>
            </div>
            <div className="open-position-card__price-column">
              <div className="open-position-card__label">{t('trade.pl')}</div>
              <div className={
                classnames({
                  "open-position-card__value": true,
                  "open-position-card__value_green": Number(defaultProfit) > 0.0,
                  "open-position-card__value_red": Number(defaultProfit) < 0.0,
                })
              }>{formatCurrency(defaultProfit, accountCurrency)}</div>
            </div>
          </div>
        </div>
      </a>
      <OpenTradeModal
        defaultProfit={defaultProfit}
        ticket={ticket}
        accountNumber={accountNumber}
        accountCurrency={accountCurrency}
        baseCurrency={baseCurrency}
        quoteCurrency={quoteCurrency}
        side={side}
        openTimeText={openTimeText}
        openTimeShortText={openTimeShortText}
        openTimeWarning={openTimeWarning}
        openPrice={openPrice}
        currentPrice={currentPrice}
        takeProfit={takeProfit}
        stopLoss={stopLoss}
        show={showModal}
        handleClose={handleClose}
        setHasChange={setHasChange}
      />
    </>
  )
};

export default TradeCard;