import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Subscription, SubscriptionState } from '../../types/subscription';
import { fetchMargin } from '../../utils/account_fetcher';
import { Account } from '../../types/account';
import SubscriptionModal from './subscription_modal';

interface Props {
  massTitle: string;
  bigBossDepositUrl: string;
  subscriptionNote: string | null;
  accountCurrency: string|null;
  isFollowerAccountLocked: boolean;
  defaultSubscription: Subscription,
  locale?: string;
};

const OnboardingContents: React.FC<Props> = ({
  massTitle,
  bigBossDepositUrl,
  subscriptionNote,
  accountCurrency,
  isFollowerAccountLocked,
  defaultSubscription,
  locale,
}) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'onboarding' });
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [account, setAccount] = useState<Account>({
    currency: null, balance: null, equity: null, prevBalance: null, prevEquity: null
  });
  const { balance, equity } = account;
  const [
    subscriptionState, setSubscriptionState
  ] = useState<SubscriptionState>({
    subscription: defaultSubscription,
    isSubmitting: false,
    errorMessage: null,
  });

  useEffect(() => {
    fetchMargin(setAccount)
  }, []);

  if (!!locale) {
    useEffect(() => {
      i18n.changeLanguage(locale);
    }, [locale]);
  }

  useEffect(() => {
    if (subscriptionState.subscription.updatedAt > 0) {
      window.location.reload();
    }
  }, [subscriptionState.subscription.updatedAt]);

  if (!balance || !equity) return (
    <p className="text-center mt-3">{t('loading')}</p>
  );

  if (Number(balance) === 0.0 && Number(equity) === 0.0) return(
    <>
      <h3 className="card-title">{t('welcome', { massTitle: massTitle })}</h3>
      <p className="mb-2">{t('no_balance_message')}</p>
      {subscriptionNote && <p className="mb-2">{subscriptionNote}</p>}
      <a href={bigBossDepositUrl} target="_blank" className="btn btn-lg btn-warning">
        <i className="las la-piggy-bank"></i>
        {' '}
        {t('open_my_page_button')}
      </a>
    </>
  );

  return(
    <>
      <h3 className="card-title">{t('welcome', { massTitle: massTitle })}</h3>
      <p className="mb-2">{t('ready_message')}</p>
      <button className="btn btn-lg btn-primary" onClick={() => setIsModalShown(true)}>
        <i className="las la-magic"></i>
        {' '}
        {t('subscription_button')}
      </button>
      <SubscriptionModal
        accountCurrency={accountCurrency}
        isFollowerAccountLocked={isFollowerAccountLocked}
        subscriptionNote={subscriptionNote}
        subscriptionState={subscriptionState}
        setSubscriptionState={setSubscriptionState}
        show={isModalShown}
        setShow={setIsModalShown}
      />
    </>
  );
};

export default OnboardingContents;