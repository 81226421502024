import React from 'react';
import Switch from "react-switch";

interface Props {
  checked: boolean;
  onChange: (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => void;
};

const ToggleSwitch: React.FC<Props> = ({
  checked, onChange
}) => {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      // onColor="#f0f0f0"
      onColor="#9DB2CE"
      onHandleColor="#2693e6"
      uncheckedIcon={false}
      checkedIcon={false}
      handleDiameter={30}
      height={20}
      width={48}
    />
  )
};

export default ToggleSwitch;