import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SubscriptionModal from './subscription_modal';
import { useTranslation } from 'react-i18next';
import { Subscription, SubscriptionState } from '../../types/subscription';

interface Props {
  accountCurrency: string|null;
  subscriptionNote: string|null;
  isFollowerAccountLocked: boolean;
  defaultSubscription: Subscription,
  locale?: string;
};

const HomeSubscriptionStatus: React.FC<Props> = ({
  accountCurrency,
  subscriptionNote,
  isFollowerAccountLocked,
  defaultSubscription,
  locale,
}) => {
  const { t, i18n } = useTranslation();
  const [isActive, setIsActive] = useState<boolean|null>(null);
  const [lotMultiplierPct, setLotMultiplierPct] = useState<number|null>(null);
  const [reverseTrades, setReverseTrades] = useState<boolean|null>(null);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [
    subscriptionState, setSubscriptionState
  ] = useState<SubscriptionState>({
    subscription: defaultSubscription,
    isSubmitting: false,
    errorMessage: null,
  });

  const fetch = async () => {
    setIsActive(null);
    setLotMultiplierPct(null);
    setReverseTrades(null);

    try {
      const url = `/f/subscription/fetch`;
      const response = await axios.get(url, {
        timeout: 8000,
      });
      if (response.status === 200) {
        setIsActive(response.data.isActive);
        setLotMultiplierPct(response.data.lotMultiplierPct);
        setReverseTrades(response.data.reverseTrades);
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (e) {
      if (e.response?.data?.message) {
        alert(e.response.data.message);
      } else {
        console.log('Something went wrong');
        console.log(e);
      }
    }
  }

  useEffect(() => {
    fetch()
  }, [subscriptionState.subscription.updatedAt]);

  if (!!locale) {
    useEffect(() => {
      i18n.changeLanguage(locale);
    }, [locale]);
  }

  const buildColumn = (label: string, value: string|null) => (
    <div className="col-3 text-center px-0">
      <div className="follower-home__status-label">{label}</div>
      {value === null ? <div className="follower-home__status-value follower-home__status-value_muted">
        <div className="text-spin follower-home__spinner-wrapper">
          <i className="las la-spinner"></i>
        </div>
      </div> : <a
        className="follower-home__status-value"
        onClick={ (e) => { e.preventDefault(); setIsModalShown(true); }}
        href="#">
        {value}
      </a>}
    </div>
  );

  return (
    <>
      <div className="row mb-2">
        <div className="col-6">
          <h3 className="follower-home__heading">{t('home.mass_autopilot')}</h3>
        </div>
        <div className="col-6">
          <button
            className="follower-home__settings-button float-end"
            onClick={() => setIsModalShown(true)}>
            <i className="las la-magic"></i>{` ${t('settings')}`}
          </button>
        </div>
      </div>
      <div className="row justify-content-evenly mb-3">
        {buildColumn(t('status'), isActive === null ? null : isActive ? 'ON' : 'OFF')}
        {buildColumn(t('lot_size'), lotMultiplierPct === null ? null : `${lotMultiplierPct}%`)}
        {buildColumn(t('reverse_mode'), reverseTrades === null ? null : reverseTrades ? 'ON' : 'OFF')}
      </div>
      <SubscriptionModal
        accountCurrency={accountCurrency}
        subscriptionNote={subscriptionNote}
        isFollowerAccountLocked={isFollowerAccountLocked}
        subscriptionState={subscriptionState}
        setSubscriptionState={setSubscriptionState}
        show={isModalShown}
        setShow={setIsModalShown}
      />
    </>
  );
};

export default HomeSubscriptionStatus;