import React from "react"

class HelloReact extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(<>Hello React! {this.props.testProp}</>);
  }
}

export default HelloReact;