import React, { useState, useEffect } from 'react';
import { formatCurrency } from '../../utils/format_currency';
import { useTranslation } from 'react-i18next';
import { fetchMargin } from '../../utils/account_fetcher';
import { Account } from '../../types/account';

interface Props {
  followerAccountUid: string;
  accountNumber: number;
  accountName: string;
  defaultCurrency: string;
  locale?: string;
};

const HomeAccountStatus: React.FC<Props> = ({
  followerAccountUid,
  accountNumber,
  accountName,
  defaultCurrency,
  locale,
}) => {
  const { t, i18n } = useTranslation();
  const [account, setAccount] = useState<Account>({
    currency: defaultCurrency, balance: null, equity: null, prevBalance: null, prevEquity: null
  });
  const { currency, balance, equity, prevBalance, prevEquity } = account;

  useEffect(() => {
    fetchMargin(setAccount);
  }, [followerAccountUid]);

  if (!!locale) {
    useEffect(() => {
      i18n.changeLanguage(locale);
    }, [locale]);
  }

  const balanceChange = balance && prevBalance ? Number(balance) - Number(prevBalance) : null;
  const equityChange = equity && prevEquity ? Number(equity) - Number(prevEquity) : null;
  const balanceChangePct = balanceChange && prevBalance ? balanceChange / Number(prevBalance) * 100 : null;
  const equityChangePct = equityChange && prevEquity ? equityChange / Number(prevEquity) * 100 : null;

  const changeToHtmlClass = (change: number|null) => {
    if (change === null) return '';
    if (change > 0) return 'follower-home__change_green';
    if (change < 0) return 'follower-home__change_red';
    return '';
  }

  return (
    <>
      <div className="d-md-none text-center">
        <div className="follower-home__account">{accountName}</div>
        <div className="follower-home__account">{t('mass_account')} #{accountNumber}</div>
        <div className="follower-home__balance">
          {balance === null ? <div className="text-spin follower-home__spinner-wrapper">
            <i className="las la-spinner"></i>
          </div> : <>
            {formatCurrency(balance, currency)}
          </>}
        </div>
        {balance !== null && <div className={`follower-home__change ${changeToHtmlClass(balanceChange)}`}>
          {balanceChange !== null && t('daily_change')}
          {balanceChange !== null && formatCurrency(balanceChange, currency)}
          {balanceChangePct !== null && ` (${balanceChangePct.toFixed(2)}%)`}
        </div>}
      </div>

      <div className="d-none d-md-block">
        <div className="row">
          <div className="col-4">
            <div className="follower-home__heading">{t('mass_account')}</div>
            <div className="follower-home__balance">#{accountNumber}</div>
            <div className="follower-home__change">{accountName}</div>
          </div>
          <div className="col-4">
            <div className="follower-home__heading">{t('balance')}</div>
            <div className="follower-home__balance">
              {balance === null ? <div className="text-spin follower-home__spinner-wrapper">
                <i className="las la-spinner"></i>
              </div> : <>
                {formatCurrency(balance, currency)}
              </>}
            </div>
            {balance !== null && <div className={`follower-home__change ${changeToHtmlClass(balanceChange)}`}>
              {balanceChange !== null && t('daily_change')}
              {balanceChange !== null && formatCurrency(balanceChange, currency)}
              {balanceChangePct !== null && ` (${balanceChangePct.toFixed(2)}%)`}
            </div>}
          </div>
          <div className="col-4">
            <div className="follower-home__heading">{t('equity')}</div>
            <div className="follower-home__balance">
              {equity === null ? <div className="text-spin follower-home__spinner-wrapper">
                <i className="las la-spinner"></i>
              </div> : <>
                {formatCurrency(equity, currency)}
              </>}
            </div>
            {equity !== null && <div className={`follower-home__change ${changeToHtmlClass(equityChange)}`}>
              {equityChange !== null && t('daily_change')}
              {equityChange !== null && formatCurrency(equityChange, currency)}
              {equityChangePct !== null && ` (${equityChangePct.toFixed(2)}%)`}
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAccountStatus;