import React, { useState } from 'react';
import OpenTradeModal from './open_trade_modal';

interface Props {
  buttonLabel: string;
  defaultProfit: string;
  ticket: number;
  accountNumber: number;
  accountCurrency: string | null;
  baseCurrency: string;
  quoteCurrency: string;
  side: string;
  openTimeText: string;
  openTimeShortText: string;
  openPrice: string;
  currentPrice: string;
  takeProfit: string;
  stopLoss: string;
};

const ActionsButton: React.FC<Props> = ({
  buttonLabel,
  defaultProfit,
  ticket,
  accountNumber,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  side,
  openTimeText,
  openTimeShortText,
  openPrice,
  currentPrice,
  takeProfit,
  stopLoss,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState<boolean>(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => {
    if (hasChange) {
      window.location.reload();
    } else {
      setShowModal(false);
    }
  };

  return (
    <>
      <button name="button" className="position-table__actions-button" onClick={handleOpen}>
        {buttonLabel}
      </button>
      <OpenTradeModal
        defaultProfit={defaultProfit}
        ticket={ticket}
        accountNumber={accountNumber}
        accountCurrency={accountCurrency}
        baseCurrency={baseCurrency}
        quoteCurrency={quoteCurrency}
        side={side}
        openTimeText={openTimeText}
        openTimeShortText={openTimeShortText}
        openPrice={openPrice}
        currentPrice={currentPrice}
        takeProfit={takeProfit}
        stopLoss={stopLoss}
        show={showModal}
        handleClose={handleClose}
        setHasChange={setHasChange}
      />
    </>
  )
};

export default ActionsButton;