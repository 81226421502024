const formatCurrency = (
  value: number|string, currency: string|null
): string => {
  if (!!currency) {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
    });
  } else {
    return value.toString();
  }
};

export { formatCurrency };