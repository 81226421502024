import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';
import { numberToFieldValue, fieldValueToNumber } from '../../utils/number_helper';
import { Subscription, SubscriptionState } from '../../types/subscription';
import { updateSubscription, writeComment } from '../../utils/subscription_handler';

interface Props {
  accountCurrency: string | null,
  isFollowerAccountLocked: boolean,
  subscriptionNote: string | null,
  subscriptionState: SubscriptionState,
  setSubscriptionState: React.Dispatch<React.SetStateAction<SubscriptionState>>,
  show: boolean,
  setShow: (show: boolean) => void,
};

const SubscriptionModal: React.FC<Props> = ({
  accountCurrency,
  isFollowerAccountLocked,
  subscriptionNote,
  subscriptionState,
  setSubscriptionState,
  show,
  setShow,
}) => {
  const { t } = useTranslation();
  const subscription = subscriptionState.subscription;
  const setSubscription = (subscription: Partial<Subscription>) => {
    setSubscriptionState({
      ...subscriptionState,
      subscription: {
        ...subscriptionState.subscription,
        ...subscription,
      },
    });
  };

  const handleSubmitButtonClick = async (e) => {
    e.preventDefault();
    setSubscriptionState({
      ...subscriptionState, isSubmitting: true
    });
    const isUpdateSuccessful = await updateSubscription(
      subscriptionState, setSubscriptionState
    );
    if (isUpdateSuccessful) {
      setShow(false);
    }
  }

  // Retry writing AccountComment every 5 seconds if there is an unwritten comment
  const hasUnwrittenCommentRef = React.useRef(subscription.hasUnwrittenComment);
  useEffect(() => {
    if (!subscription.hasUnwrittenComment) { return; }
    const timer = setInterval(() => {
      if (!hasUnwrittenCommentRef.current) { clearInterval(timer); }
      writeComment(subscriptionState, setSubscriptionState);
    }, 5000);
    return () => clearInterval(timer);
  }, [subscription.updatedAt]);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <i className="las la-magic"></i>
          {' '}
          {t('subscription_modal.title')}
        </Modal.Title>
      </Modal.Header>

      {isFollowerAccountLocked ? <Modal.Body>
        <p className="text-danger">
          <i className="las la-exclamation-triangle"></i>
          {t('subscription_modal.account_locked_warning')}
        </p>
      </Modal.Body> : <Modal.Body>
        <div className="subscription-modal__toggle-switch-group">
          <label className="subscription-modal__toggle-switch-label">{t('status')}</label>
          <Switch
            onChange={() => setSubscription({ isActive: !subscription.isActive })}
            checked={subscription.isActive}
            onColor="#9DB2CE"
            onHandleColor="#2693e6"
            offHandleColor="#bcbcbc"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={30}
            height={20}
            width={48}
          />
        </div>

        {subscription.isActive && <div>
          <div className="form-group mt-1">
            <label className="form-label subscription-modal__label" htmlFor="base_lot_multiplier_pct">
              {t('subscription_modal.lot_size_label')}
            </label>
            <div className="input-group">
              <span className="input-group-text">x</span>
              <input type="text" className="form-control" id="base_lot_multiplier_pct"
                value={numberToFieldValue(subscription.baseLotMultiplierPct)}
                onChange={ (e) => setSubscription({
                  baseLotMultiplierPct: fieldValueToNumber(e.target.value)
                })}
              />
              <span className="input-group-text">%</span>
            </div>
          </div>

          {subscription.autoMM && <div className="form-group mt-1">
            <label className="form-label subscription-modal__label" htmlFor="base_equity">
              {t('subscription_modal.base_equity_label')}
            </label>
            <div className="input-group">
              <span className="input-group-text">{t('subscription_modal.per')}</span>
              <input type="text" className="form-control"
                value={numberToFieldValue(subscription.baseEquity)}
                onChange={ (e) => setSubscription({
                  baseEquity: fieldValueToNumber(e.target.value)
                })}
              />
              <span className="input-group-text">{accountCurrency}</span>
            </div>
          </div>}

          {subscriptionNote && <p className="mt-1 text-small">{subscriptionNote}</p>}

          {subscription.reverseTrades && <p className="text-danger mt-2 mb-0">
            <i className="las la-exclamation-triangle"></i>
            {t('subscription_modal.reverse_mode_warning')}
          </p>}
          {subscription.hasUnwrittenComment && <p className="text-danger mt-2 mb-0">
            <i className="las la-exclamation-triangle"></i>
            {t('subscription_modal.unwritten_comment_warning')}
          </p>}
        </div>}

        <hr />
        {subscriptionState.errorMessage && <p className="text-danger">
          {subscriptionState.errorMessage}
        </p>}
        <div className="d-flex justify-content-between">
          <a className="btn btn-outline-secondary" data-turbo="false" href="/f/settings/advanced">
            {t('subscription_modal.advanced_settings')}
          </a>
          <Button
            variant="primary"
            disabled={subscriptionState.isSubmitting || subscription.hasUnwrittenComment}
            onClick={handleSubmitButtonClick}
          > {subscriptionState.isSubmitting ? <Spinner
              as="span"
              size="sm"
              animation="border"
              role="status"
              aria-hidden="true"
            /> : t('apply')}
          </Button>
        </div>
      </Modal.Body>}
    </Modal>
  );
};

export default SubscriptionModal;