const countryCurrencyMap = [
  { flag: 'us', currency: 'USD' },
  { flag: 'eu', currency: 'EUR' },
  { flag: 'jp', currency: 'JPY' },
  { flag: 'au', currency: 'AUD' },
  { flag: 'nz', currency: 'NZD' },
  { flag: 'gb', currency: 'GBP' },
  { flag: 'ch', currency: 'CHF' },
  { flag: 'sg', currency: 'SGD' },
  { flag: 'hk', currency: 'HKD' },
  { flag: 'ae', currency: 'AED' },
  { flag: 'br', currency: 'BRL' },
  { flag: 'ca', currency: 'CAD' },
  { flag: 'cn', currency: 'CNY' },
  { flag: 'in', currency: 'INR' },
  { flag: 'mx', currency: 'MXN' },
  { flag: 'my', currency: 'MYR' },
  { flag: 'ph', currency: 'PHP' },
  { flag: 'sa', currency: 'SAR' },
  { flag: 'se', currency: 'SEK' },
  { flag: 'th', currency: 'THB' },
  { flag: 'za', currency: 'ZAR' },
]

// https://flagicons.lipis.dev/
const currencyToFlagCode = (currency: string) => {
  const country = countryCurrencyMap.find(
    (country) => country.currency === currency
  );
  return country ? country.flag : 'xx';
};

export { currencyToFlagCode };
