import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Props {
  quoteIntervalMs: number;
  quoteUrl: string;
};

type Quote = {
  symbol: string;
  bid: number;
  ask: number;
  bidDirection: 'up' | 'down';
  askDirection: 'up' | 'down';
  ageSeconds: number;
};

const FooterNewsNavbarText: React.FC<Props> = ({
  quoteIntervalMs,
  quoteUrl,
}) => {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [nowText, setNowText] = useState<string>("");

  const quote = async () => {
    try {
      const url = quoteUrl;
      const response = await axios.get(url, {
        timeout: 8000,
      });
      if (response.status === 200) {
        setQuotes(response.data.quotes.map((quote: any) => ({
          symbol: quote.symbol,
          bid: quote.bid,
          ask: quote.ask,
          bidDirection: quote.bid_direction,
          askDirection: quote.ask_direction,
          ageSeconds: quote.age_seconds,
        })));
        setNowText(response.data.now_text);
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (e) {
      if (e.response?.data?.message) {
        console.log(e.response.data.message);
      } else {
        // Just give up silently since the feature is non-critical.

        // console.log('Something went wrong');
        // console.log(e);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      quote();
    }, 3000);
    const timer = setInterval(() => {
      quote();
    }, quoteIntervalMs);
    return () => clearInterval(timer);
  }, []);

  if (quotes.length === 0) return (<></>);

  const directionToHtmlClass = (direction: 'up' | 'down', ageSeconds: number) => {
    if (direction === 'up' && ageSeconds < 310) {
      return 'text-green';
    } else if (direction === 'down' && ageSeconds < 310) {
      return 'text-red';
    } else {
      return '';
    }
  }

  const text = quotes.map((quote) => {
    const bidHtmlClass = directionToHtmlClass(quote.bidDirection, quote.ageSeconds);
    const askHtmlClass = directionToHtmlClass(quote.askDirection, quote.ageSeconds);
    return [
      `${quote.symbol} - `,
      `Bid <span class="${bidHtmlClass}">${quote.bid}</span> / `,
      `Ask <span class="${askHtmlClass}">${quote.ask}</span>`
    ].join('');
  }).join(' | ');

  return (
    <div className="navbar-text pc-footer-nav__news-text">
      <span className="text-white text-bold">{nowText}</span>
      {' '}
      <div className="d-inline-block" dangerouslySetInnerHTML={{__html: text}} />
    </div>
  );
}

export default FooterNewsNavbarText;