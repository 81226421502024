/* eslint no-console:0 */

import "@hotwired/turbo-rails"
import 'bootstrap';
import 'src/spend-one-time-login-token';
import drawBalanceChart from "./src/balance-chart";
import {activateTooltip} from "./src/tooltip";

// i18n
// https://react.i18next.com/latest/using-with-hooks
import 'i18n/i18n';

window.addEventListener('turbo:load', (event) => {
  drawBalanceChart();
  activateTooltip();
});
window.addEventListener('turbo:frame-load', (event) => {
  activateTooltip();
});

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// https://github.com/reactjs/react-rails/issues/1103
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount);