import axios from 'axios';
import { Account } from '../types/account';

const fetchMargin = async (
  setAccount: React.Dispatch<React.SetStateAction<Account>>,
) => {
  try {
    const url = `/f/fetch_margin`;
    const response = await axios.get(url, {
      timeout: 15000,
    });
    if (response.status === 200) {
      setAccount({
        currency: response.data.currency,
        balance: response.data.balance,
        equity: response.data.equity,
        prevBalance: response.data.balance_prev_day,
        prevEquity: response.data.equity_prev_day,
      });
      return(true);
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (e) {
    if (e.response?.data?.message) {
      alert(e.response.data.message);
    } else {
      console.log('Something went wrong');
      console.log(e);
    }
    return(false);
  }
};

export { fetchMargin };