/*
This component is not currently used.
This feature will be implemented in the future, when AWK supports modifying trades.
*/

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import Rails from 'rails-ujs';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

interface Props {
  defaultPrice: string;
  ticket: number;
  accountCurrency: string | null;
  setHasChange?: (hasChange: boolean) => void;
};

const TpSlField: React.FC<Props> = ({
  defaultPrice,
  ticket,
  accountCurrency,
  setHasChange,
}) => {
  const { t, i18n } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [price, setPrice] = useState<string>(defaultPrice);

  const modifyTrade = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  }

  return (
    <div className="input-group">
      <input
        type="number"
        value={price}
        className="form-control form-control-sm"
        step="0.0001"
      />
      <Button
        variant="outline-success"
        disabled={isSubmitting}
        onClick={modifyTrade}
        size="sm"
        className="open-position-modal__modify-button"
      > {isSubmitting ? <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : 'Modify'}
      </Button>
    </div>
  );
};

export default TpSlField;