import axios from 'axios';
import Rails from 'rails-ujs';
import { SubscriptionState } from '../types/subscription';
import i18next from 'i18next';

const updateSubscription = async (
  subscriptionState: SubscriptionState,
  setSubscriptionState: React.Dispatch<React.SetStateAction<SubscriptionState>>,
) => {
  try {
    const url = `/f/subscription`;
    const subscription = subscriptionState.subscription;
    const response = await axios.put(url, {
      isActive: subscription.isActive,
      autoMM: subscription.autoMM,
      baseLotMultiplierPct: subscription.isActive ? subscription.baseLotMultiplierPct : '0',
      baseEquity: (subscription.isActive && subscription.autoMM) ? subscription.baseEquity : '0',
      reverseTrades: (subscription.isActive && subscription.reverseTrades) ? subscription.reverseTrades : '0',
      authenticity_token: Rails.csrfToken(),
    }, {
      timeout: 15000,
    });

    if (response.status === 200) {
      setSubscriptionState({
        subscription: {
          isActive: response.data.isActive,
          autoMM: response.data.autoMM,
          baseLotMultiplierPct: response.data.baseLotMultiplierPct,
          baseEquity: response.data.baseEquity,
          reverseTrades: response.data.reverseTrades,
          hasUnwrittenComment: response.data.hasUnwrittenComment,
          updatedAt: response.data.updatedAt,
        },
        isSubmitting: false,
        errorMessage: null,
      });
      return(true);
    } else {
      console.log(`Unexpected status code: ${response.status}`);
      return(false);
    }
  } catch (e) {
    if (e.response?.data?.message) {
      setSubscriptionState({
        ...subscriptionState,
        isSubmitting: false,
        errorMessage: e.response.data.message,
      });
    } else if (e.message.match(/^timeout/)) {
      setSubscriptionState({
        ...subscriptionState,
        isSubmitting: false,
        errorMessage: i18next.t('timeout_message'),
      });
    } else {
      console.log('Something went wrong');
      console.log(e);
    }
    return(false);
  }
}

const writeComment = async (
  subscriptionState: SubscriptionState,
  setSubscriptionState: React.Dispatch<React.SetStateAction<SubscriptionState>>,
) => {
  try {
    const url = `/f/subscription/write_comment`;
    const response = await axios.put(url, {
      authenticity_token: Rails.csrfToken(),
    }, {
      timeout: 8000,
    });

    if (response.status === 200) {
      setSubscriptionState({
        subscription: {
          isActive: response.data.isActive,
          autoMM: response.data.autoMM,
          baseLotMultiplierPct: response.data.baseLotMultiplierPct,
          baseEquity: response.data.baseEquity,
          reverseTrades: response.data.reverseTrades,
          hasUnwrittenComment: response.data.hasUnwrittenComment,
          updatedAt: response.data.updatedAt,
        },
        isSubmitting: false,
        errorMessage: null,
      });
      return(true);
    } else {
      console.log(`Unexpected status code: ${response.status}`);
      return(false);
    }
  } catch (e) {
    if (e.response.data?.message) {
      setSubscriptionState({
        ...subscriptionState,
        isSubmitting: false,
        errorMessage: e.response.data.message,
      });
    } else if (e.message.match(/^timeout/)) {
      setSubscriptionState({
        ...subscriptionState,
        isSubmitting: false,
        errorMessage: i18next.t('timeout_message'),
      });
    } else {
      console.log('Something went wrong');
      console.log(e);
    }
    return(false);
  }
};

export { updateSubscription, writeComment };