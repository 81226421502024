import React, { useState } from 'react';
import Rails from 'rails-ujs';
import axios from 'axios';

type Props = {
  followerAccountUid: string,
  currency: string|null,
  defaultBalance: string|null,
  defaultEquity: string|null,
  defaultFetchedAtText: string|null,
  defaultFetchedAgoText: string|null,
};

const BalanceColumnContent: React.FC<Props> = ({
  followerAccountUid,
  currency,
  defaultBalance,
  defaultEquity,
  defaultFetchedAtText,
  defaultFetchedAgoText,
}) => {
  const [balance, setBalance] = useState<string|null>(defaultBalance);
  const [equity, setEquity] = useState<string|null>(defaultEquity);
  const [fetchedAtText, setFetchedAtText] = useState<string|null>(defaultFetchedAtText);
  const [fetchedAgoText, setFetchedAgoText] = useState<string|null>(defaultFetchedAgoText);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdate = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const url = `/p/follower_accounts/${followerAccountUid}/update_margin`;
      const response = await axios.put(url, {
         authenticity_token: Rails.csrfToken(),
      }, {
        timeout: 8000,
      });
      if (response.status === 200) {
        setBalance(response.data.balance);
        setEquity(response.data.equity);
        setFetchedAtText(response.data.fetchedAtText);
        setFetchedAgoText(response.data.fetchedAgoText);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (e) {
      setIsLoading(false);
      if (e.response.data?.message) {
        alert(e.response.data.message);
      } else {
        console.log('Something went wrong');
        console.log(e);
      }
    }
  }

  return (
    <>
      {!!fetchedAtText && <>
        {currency} {balance} / {equity}
        <br />
      </>}
      <div className="d-flex">
        {!!fetchedAtText ? <a
          title={fetchedAtText?.toString()}
          onClick={(e) => { e.preventDefault(); }}
          className="text-muted text-small text-undecorated d-block me-1">
          {fetchedAgoText}
        </a> : <div className="text-muted me-1">
          {fetchedAgoText ?? 'Unknown'}
        </div>}
        {!isLoading && <a onClick={handleUpdate} href="#" className="text-cyan">
          <i className="las la-sync-alt"></i>
        </a>}
        {isLoading && <div className="text-spin"><i className="las la-sync-alt"></i></div>}
      </div>
    </>
  );
}

export default BalanceColumnContent;