import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ToggleSwitch from './toggle_switch';
import { useTranslation } from 'react-i18next';
import { numberToFieldValue, fieldValueToNumber } from '../../utils/number_helper';
import { Subscription, SubscriptionState } from '../../types/subscription';
import { updateSubscription, writeComment } from '../../utils/subscription_handler';

interface Props {
  accountCurrency: string;
  subscriptionNote: string|null;
  defaultSubscription: Subscription,
  locale?: string;
};

const AdvancedSettingsForm: React.FC<Props> = ({
  accountCurrency,
  subscriptionNote,
  defaultSubscription,
  locale,
}) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'advanced_settings_form' });
  const [
    subscriptionState, setSubscriptionState
  ] = useState<SubscriptionState>({
    subscription: defaultSubscription,
    isSubmitting: false,
    errorMessage: null,
  });

  const subscription = subscriptionState.subscription;
  const setSubscription = (subscription: Partial<Subscription>) => {
    setSubscriptionState({
      ...subscriptionState,
      subscription: {
        ...subscriptionState.subscription,
        ...subscription,
      },
    });
  };
  const { isSubmitting, errorMessage } = subscriptionState;

  if (!!locale) {
    useEffect(() => {
      i18n.changeLanguage(locale);
    }, [locale]);
  }

  const handleSubmitButtonClick = async (e) => {
    e.preventDefault();
    setSubscriptionState({
      ...subscriptionState, isSubmitting: true, errorMessage: null
    });
    await updateSubscription(subscriptionState, setSubscriptionState);
  }

  // Retry writing AccountComment every 5 seconds if there is an unwritten comment
  const hasUnwrittenCommentRef = React.useRef(subscription.hasUnwrittenComment);
  useEffect(() => {
    if (!subscription.hasUnwrittenComment) { return; }
    const timer = setInterval(() => {
      if (!hasUnwrittenCommentRef.current) { clearInterval(timer); }
      writeComment(subscriptionState, setSubscriptionState);
    }, 5000);
    return () => clearInterval(timer);
  }, [subscription.updatedAt]);

  return (
    <form className="settings-form">
      <div className="settings-form__toggle-switch-group">
        <label className="settings-form__toggle-switch-label">{t('status_label')}</label>
        <ToggleSwitch
          onChange={() => setSubscription({ isActive: !subscription.isActive })}
          checked={subscription.isActive}
        />
      </div>
      {subscription.isActive && <div>
        <h2 className="settings-form__heading mt-3">{t('lot_size_heading')}</h2>
        <div className="settings-form__toggle-switch-group">
          <label className="settings-form__toggle-switch-label">{t('auto_lot_label')}</label>
          <ToggleSwitch
            onChange={() => setSubscription({ autoMM: !subscription.autoMM })}
            checked={subscription.autoMM}
          />
        </div>
        <div className="form-group">
          <label className="form-label settings-form__label" htmlFor="base_lot_multiplier_pct">
            {t('lot_size_label')}
          </label>
          <div className="input-group">
            <span className="input-group-text">x</span>
            <input type="text" className="form-control" id="base_lot_multiplier_pct"
              value={numberToFieldValue(subscription.baseLotMultiplierPct)}
              onChange={ (e) => setSubscription({
                baseLotMultiplierPct: fieldValueToNumber(e.target.value)
              })}
            />
            <span className="input-group-text">%</span>
          </div>
        </div>
        {subscription.autoMM && <div className="form-group mt-1">
          <label className="form-label settings-form__label" htmlFor="base_equity">
            {t('base_equity_label')}
          </label>
          <div className="input-group">
            <span className="input-group-text">{t('per')}</span>
            <input type="text" className="form-control"
              value={numberToFieldValue(subscription.baseEquity)}
              onChange={ (e) => setSubscription({
                baseEquity: fieldValueToNumber(e.target.value)
              })}
            />
            <span className="input-group-text">{accountCurrency}</span>
          </div>
        </div>}
      </div>}

      {subscriptionNote && <p className="mt-1 text-small">{subscriptionNote}</p>}

      {subscription.isActive && <div>
        <h2 className="settings-form__heading mt-3">{t('reverse_trades_heading')}</h2>
        <div className="settings-form__toggle-switch-group">
          <label className="settings-form__toggle-switch-label">
            {t('reverse_mode_label')}
          </label>
          <ToggleSwitch
            onChange={() => setSubscription({ reverseTrades: !subscription.reverseTrades })}
            checked={subscription.reverseTrades}
          />
        </div>
      </div>}

      <div className="d-flex mt-3 align-items-center">
        <Button
          variant="primary"
          disabled={isSubmitting || subscription.hasUnwrittenComment}
          onClick={handleSubmitButtonClick}
          className="btn btn-lg btn-primary settings-form__button">
          {isSubmitting ? <Spinner
            as="span"
            size="sm"
            animation="border"
            role="status"
            aria-hidden="true"
          /> : t('apply')}
        </Button>
        <div className="ms-2 text-grey text-small">
          {isSubmitting && <>{t('do_not_reload_note')}</>}
          {subscription.hasUnwrittenComment && <>{t('unwritten_comment_warning')}</>}
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </div>
      </div>
    </form>
  )
}

export default AdvancedSettingsForm;