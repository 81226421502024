import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import Rails from 'rails-ujs';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { formatCurrency } from '../../../utils/format_currency';

interface Props {
  defaultProfit: string;
  ticket: number;
  accountNumber: number;
  accountCurrency: string | null;
  setHasChange?: (hasChange: boolean) => void;
};

const ProfitLossSection: React.FC<Props> = ({
  defaultProfit,
  ticket,
  accountNumber,
  accountCurrency,
  setHasChange,
}) => {
  const { t, i18n } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [profit, setProfit] = useState<string>(defaultProfit);
  const [errorMessage, setErrorMessage] = useState<string|null>(null);

  const closeTrade = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const url = `/f/mt4_open_trades/${ticket}/close.json`;
      const response = await axios.put(url, {
        authenticity_token: Rails.csrfToken(),
      }, {
        timeout: 8000,
      });
      console.log(response.data);
      if (response.status === 200) {
        setIsSubmitting(false);
        setProfit(response.data.profit);
        setIsClosed(response.data.close_time > 0);
        setErrorMessage(null);
        if (setHasChange) {
          setHasChange(true);
        }
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (e) {
      if (e.response.data?.message) {
        setErrorMessage(e.response.data.message);
        setIsSubmitting(false);
      } else if (e.message.match(/^timeout/)) {
        setErrorMessage(t('timeout_message'));
        setIsSubmitting(false);
      } else {
        console.log('Something went wrong');
        console.log(e);
      }
    }
  }

  // Format profit/loss
  const plText = formatCurrency(profit, accountCurrency);

  return (
    <div className="text-center">
      {Number(profit) < 0.0 ? t('loss') : t('profit')}
      <div className={classnames(
        'open-position-modal__pl-value',
        Number(profit) > 0.0 && 'text-glow-blinking'
      )}>
        {plText}
      </div>
      {isClosed && <>
        <p className="mb-1">
          {Number(profit) > 0.0 ? t('closed_with_profit') : t('closed_with_loss')}
        </p>
      </>}
      {/* </> : <>
        <label className="text-small text-muted">
          <input type="checkbox" value="1" checked={isDisclaimerChecked}
            onChange={() => setIsDisclaimerChecked(!isDisclaimerChecked)}
          />
          {` ${t('agree_to_old_price')}`}
        </label>
        <br />
        <Button
          variant="outline-success"
          disabled={isSubmitting || !isDisclaimerChecked}
          onClick={closeTrade}
        > {isSubmitting ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          /> : t('close_position')}
        </Button>
      </>} */}
      {errorMessage && <p className="text-danger mt-2 mb-0">{errorMessage}</p>}
    </div>
  );
};

export default ProfitLossSection;