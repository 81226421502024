const numberToFieldValue = (value: number | null): string => {
  if (value === null || Number.isNaN(value)) {
    return '';
  } else {
    return Number(value).toString();
  }
}

const fieldValueToNumber = (value: string): number | null => {
  const cleansedValue = value.match(/\d/g)?.join('');
  if (Number.isNaN(cleansedValue)) {
    return null;
  } else {
    return Number(cleansedValue);
  }
}

export {
  numberToFieldValue, fieldValueToNumber
};
