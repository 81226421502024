import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CurrencyIcon from '../currency_icon';
import ProfitLossSection from './profit_loss_section';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultProfit: string;
  ticket: number;
  accountNumber: number;
  accountCurrency: string | null;
  baseCurrency: string;
  quoteCurrency: string;
  side: string;
  openTimeText: string;
  openTimeShortText: string;
  openTimeWarning: string | null;
  openPrice: string;
  currentPrice: string;
  takeProfit: string;
  stopLoss: string;
  show: boolean;
  handleClose: () => void;
  setHasChange: (hasChange: boolean) => void;
};

const ClosedTradeModal: React.FC<Props> = ({
  defaultProfit,
  ticket,
  accountNumber,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  side,
  openTimeText,
  openTimeShortText,
  openTimeWarning,
  openPrice,
  currentPrice,
  takeProfit,
  stopLoss,
  show,
  handleClose,
  setHasChange,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="open-position-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <CurrencyIcon currency={baseCurrency} />{' '}<CurrencyIcon currency={quoteCurrency} />
            {` ${baseCurrency} / ${quoteCurrency} `}
            <span className="open-position-modal__title_muted">
              opened at {openTimeShortText}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <th className="open-position-modal__label-column" scope="row">{t('trade.ticket')}</th>
                <td className="open-position-modal__value-column">{ticket}</td>
              </tr>
              <tr>
                <th className="open-position-modal__label-column" scope="row">{t('trade.symbol')}</th>
                <td className="open-position-modal__value-column">{baseCurrency}/{quoteCurrency}</td>
              </tr>
              <tr>
                <th className="open-position-modal__label-column" scope="row">{t('trade.side')}</th>
                <td className="open-position-modal__value-column open-position-modal__value-column_capitalized">
                  {side}
                </td>
              </tr>
              <tr>
                <th className="open-position-modal__label-column" scope="row">{t('trade.open_time')}</th>
                <td className="open-position-modal__value-column">
                  {openTimeText}
                  {openTimeWarning && <p className="text-warning text-very-small mb-0">
                    {openTimeWarning}
                  </p>}
                </td>
              </tr>
              <tr>
                <th className="open-position-modal__label-column" scope="row">{t('trade.open_price')}</th>
                <td className="open-position-modal__value-column">{openPrice}</td>
              </tr>
              <tr>
                <th className="open-position-modal__label-column" scope="row">{t('trade.current_price')}</th>
                <td className="open-position-modal__value-column">{currentPrice}</td>
              </tr>
              {/* <tr>
                <th className="open-position-modal__label-column" scope="row">TakeProfit</th>
                <td className="open-position-modal__value-column">
                  {takeProfit}
                </td>
              </tr>
              <tr>
                <th className="open-position-modal__label-column" scope="row">StopLoss</th>
                <td className="open-position-modal__value-column">
                  {stopLoss}
                </td>
              </tr> */}
            </tbody>
          </table>
          <ProfitLossSection
            defaultProfit={defaultProfit}
            ticket={ticket}
            accountNumber={accountNumber}
            accountCurrency={accountCurrency}
            setHasChange={setHasChange}
          />
        </Modal.Body>
      </Modal>
    </>
  )
};

export default ClosedTradeModal;