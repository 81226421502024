import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const drawBalanceChart = () => {
  const canvas = document.getElementById('balance-chart-canvas');
  if (!!!canvas) return;
  const ctx = canvas.getContext('2d');

  new Chart(ctx, {
    type: 'line',
    data: {
      labels: ['Jan', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#3687FF',
        tension: 0.1
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 0
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        }
      },
      scales: {
        y: {
          type: 'linear',
          grid: {
            drawOnChartArea: false,
            drawBorder: false,
            drawTicks: false,
          },
          display: false,
          // ticks: {
          //   // This will place the labels inside the chart
          //   crossAlign: 'near',
          //   mirror: true,
          //   padding: -5
          // }
        },
        x: {
          type: 'category',
          display: false, // This removes the X-axis line
          grid: {
            drawOnChartArea: false,
            drawBorder: false,
            drawTicks: false
          }
        }
      }
    }
  });
};

export default drawBalanceChart;