import { Tooltip } from 'bootstrap';

const activateTooltip = () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => {
    new Tooltip(tooltipTriggerEl);
    tooltipTriggerEl.addEventListener('click', (e) => { e.preventDefault() });
  });
};

export { activateTooltip };