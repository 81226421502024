import React from 'react';
import { currencyToFlagCode } from '../../utils/country-currency';

interface Props {
  currency: string;
};

const CurrencyIcon: React.FC<Props> = ({
  currency
}) => {
  return (
    <span className={`fi fi-${currencyToFlagCode(currency)}`}></span>
  );
}

export default CurrencyIcon;